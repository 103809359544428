@use '@angular/material' as mat;
@import './theme';

body {
    /*font-family: "Montserrat" sans-serif !important;*/
    /*font-weight: 200 !important;*/
    /*font-size: 12px;*/
    color: #eeeeee !important;

    background-image: url("/assets/img/bkg_one.jpg")!important;
    background-size: cover;

    /*color: #f1f1f1;*/
}

.container {
    padding: 5vh 10vw;
}

/*button, .btn-primary, #button-light, .btn, .visually-hidden, .btn btn-lg btn-primary {*/
/*    background-color: #292929;*/
/*    border-color: #292929;*/
/*    border-radius: 1.3rem !important;*/
/*    font-family: "M PLUS 2" sans-serif !important;*/
/*    font-weight: 200 !important;*/
/*    color: #eeeeee !important;*/
/*}*/

/*button, .btn-primary:hover, .btn-primary:active, button:active{*/
/*    background-color: #1F1F1F !important;*/
/*    border-color: #1F1F1F !important;*/
/*    color: #999999 !important;*/
/*    border-radius: 1.3rem;*/
/*}*/

.required {
    position: relative
}
.required::before {
    content: '*';
    color:white;
    position: absolute;
    left: -20px;
    top: 10px;
}

.mr {
    margin-right: 1rem;
}
.mt {
    margin-top: 1rem;
}
.mt-2 {
    margin-top: 1.2rem;
}
.mt-3 {
    margin-top: 1.3rem;
}
.mb {
    margin-bottom: 1rem;
}

.remove-row {
    margin-top: 10px;
}


mat-icon.add {
    margin-top: -1rem;
}
mat-icon.remove {
    margin-left: 1rem;
}

.form-row {
    margin-bottom: 1rem;
}

.mat-form-field {
    width: 50vw;
    max-width: 400px;
    min-width: 300px;
}
.mat-form-field.three-quarter {
    width: 75vw;
    max-width: 375px;
    min-width: 250px;
}
.mat-form-field.half {
    width: 25vw;
    max-width: 250px;
    min-width: 150px;
}
.mat-form-field.quarter {
    width: 15vw;
    max-width: 140px;
    min-width: 140px;
}

.mat-form-field.mat-focused .mat-form-field-label,
.mat-form-field.mat-focused .mat-form-field-required-marker,
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{
    color:#fff;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #fff
}

section {
    padding-inline: 1em;
    padding-block: 0.7em;
    background-color: mat.get-color-from-palette($quansic-palette, 500) !important;
}


.mat-stepper-horizontal {
  background-color: mat.get-color-from-palette($quansic-palette, 500) !important;

  .mat-horizontal-content-container {
    padding-top: 12px
  }
}
.mat-mdc-form-field {
    width: 35vw;
    max-width: 450px;
}

.mdc-floating-label__float-above{
    color: mat.get-color-from-palette($quansic-palette, 50) !important;
}

.mat-mdc-form-field {
    margin-bottom: 1em;

    &.mat-focused {
        .mdc-floating-label--float-above {
            color: white !important;
        }
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing
        {
            border-color: white !important;
        }
    }

    .mdc-text-field__input {
        caret-color: white !important;
    }
}

h3 {
    margin-top: 1em !important;
}